import { css, styled } from 'styled-components'
import HomePageVideo from '../HomePageVideo/HomePageVideo'
import {
  onMediumScreen,
  onSmallScreen,
  onXXLargeScreen,
} from 'mixtiles-web-common'
import { LANGUAGES } from '../../../../locale/Translation.consts'

export const Video = styled(HomePageVideo)`
  height: 72vh;
  overflow: hidden;
`
export const TitleWrapper = styled.div`
  z-index: 2;
  padding: 44px 10px;
  height: 100%;
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.18) 44%,
    rgba(0, 0, 0, 0) 73%
  );
  background-attachment: scroll;

  ${onMediumScreen()} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 28px;
  }

  ${onXXLargeScreen()} {
    transform: scale(1.25);
    transform-origin: center;
  }
`

export const VideoBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1%;
  background: black;
`
export const TitleText = styled.h1<{
  $largerPadding: boolean
  $newTitle: boolean
  $language: string
}>`
  text-align: center;
  font-size: 10.8vw;
  line-height: 1.2;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};
  margin: 0;
  padding-top: ${({ $largerPadding }) => ($largerPadding ? '56px' : '0')};

  ${({ $newTitle }) =>
    $newTitle &&
    css`
      font-size: 12vw;
      line-height: 1.1;
    `}

  ${onSmallScreen()} {
    font-size: 50px;
  }

  ${onMediumScreen()} {
    padding-top: 0;
    font-size: 60px;
    line-height: 70px;
    font-weight: 600;
    width: 100%;
    max-width: ${({ $language }) =>
      $language === LANGUAGES.ENGLISH ? '520px' : 'min(95%, 750px)'};

    ${({ $newTitle }) =>
      $newTitle &&
      css`
        font-size: 68px;
        max-width: none;
      `}
  }
`

export const Cta = styled.button`
  ${onMediumScreen()} {
    color: white;
    font-size: 20px;
    font-weight: 700;
    border-radius: 15px;
    background: rgba(231, 216, 225, 0.3);
    backdrop-filter: blur(40px);
    padding: 20px 40px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      background: rgba(231, 216, 225, 0.7);
      color: ${({ theme }) => theme.colors.primaryAccent};
      backdrop-filter: blur(60px);
      transform: scale(0.97);
    }
  }
`

export const ChristmasImage = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: translateY(1px);
  ${onMediumScreen()} {
    transform: translateY(0);
    width: 460px;
    left: auto;
  }
`
