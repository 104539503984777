import { css, styled } from 'styled-components'
import HomePageVideo from '../HomePageVideo/HomePageVideo'
import {
  onLargeScreen,
  onMediumScreen,
  onExtraLargeScreen,
  onXXLargeScreen,
} from 'mixtiles-web-common'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import Slider from 'react-slick'

export const Container = styled.div`
  padding: 56px 0 26px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 28px;
  width: 100%;

  ${onLargeScreen()} {
    padding: 112px 0 82px 0;
    gap: 48px;
  }
`

export const Title = styled.h2`
  font-size: 28px;
  font-weight: 700;
  line-height: 120%;
  margin: 0;

  ${onLargeScreen()} {
    font-size: 42px;
    font-weight: 600;
  }

  ${onXXLargeScreen()} {
    transform: scale(1.25);
    transform-origin: center;
  }
`

export const ScrollContainer = styled.div`
  overflow: auto hidden;
  scroll-snap-type: x mandatory;
`

export const TransformedSlider = styled(Slider)`
  ${onLargeScreen()} {
    transform: translate3d(9px, 0, 0) scale(1.015); // A hack to fix the issue that the slider is not stretched to the entire WidthCapList because it contains the margin right of the next element
    margin-right: 2px;
    .slick-next {
      right: -10px;
    }
  }
`

export const ItemsContainer = styled.div`
  display: flex;
  gap: 12px;
  width: fit-content;
  padding: 0 16px 22px 16px;

  ${onMediumScreen()} {
    padding: 0;
  }

  ${onLargeScreen()} {
    max-width: 100%;

    .slick-slider {
      max-width: 100%;
    }

    .slick-list {
      transform: translate3d(10px, 0, 0) scale(1.015); // A hack to fix the issue that the slider is not stretched to the entire WidthCapList because it contains the margin right of the next element
      margin-right: 2px;
    }
  }
`

export const ItemContainer = styled.div`
  width: 75vw;
  max-width: 290px;
  border-radius: 12px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.03);
  border: 1px solid rgba(0, 0, 0, 0.07);
  overflow: hidden;
  scroll-snap-align: center;

  ${onMediumScreen()} {
    margin-bottom: 22px;
    max-width: 305px;
  }

  ${onExtraLargeScreen()} {
    max-width: 330px;
  }

  ${onXXLargeScreen()} {
    max-width: 400px;
    border-radius: 16px;
  }
`

export const Video = styled(HomePageVideo)`
  height: 407px;

  ${onExtraLargeScreen()} {
    height: 440px;
  }

  ${onXXLargeScreen()} {
    height: 550px;
  }
`

export const VideoDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 12px 20px;
  gap: 2px;
`

export const HandleText = styled.span`
  color: ${({ theme }) => theme.colors.secondaryDark};
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;

  ${onXXLargeScreen()} {
    font-size: 17px;
  }
`

export const ProductText = styled.span`
  color: ${({ theme }) => theme.colors.grayDark};
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  ${onXXLargeScreen()} {
    font-size: 16px;
  }
`

export const MuteToggle = styled.button`
  border: 0;
  border-radius: 60px;
  background: rgba(30, 30, 30, 0.5);
  width: 48px;
  height: 48px;
  color: ${({ theme }) => theme.colors.white};

  position: absolute;
  left: 12px;
  bottom: 12px;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${onMediumScreen()} {
    backdrop-filter: none;
  }

  > svg {
    width: 24px;
    height: 24px;
  }
`

export const Arrow = styled.div`
  width: 52px !important;
  height: 52px !important;
  background: white !important;
  display: grid !important;
  color: ${({ theme }) => theme.colors.grayDark} !important;

  place-items: center;
  border-radius: 50%;
  z-index: 1;
  border: 1px solid ${({ theme }) => theme.colors.grayMedium};
  box-shadow:
    0 3px 20px rgba(0, 0, 0, 0.07),
    0 4px 13px rgba(43, 5, 20, 0.06);

  &::before {
    display: none;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.secondaryDark} !important;
  }
`
const sharedArrowStyles = css`
  width: 50%;
  height: 50%;
  transition: color 0.2s;
  color: inherit;
`
export const LeftArrowIcon = styled(ArrowBackRoundedIcon)`
  ${sharedArrowStyles}
`
export const RightArrowIcon = styled(ArrowForwardRoundedIcon)`
  ${sharedArrowStyles}
`
