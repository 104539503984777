import React, { useMemo, useState } from 'react'
import * as S from './HomePageContainer.styles'
import HomePageUI from './HomePageUI'
import useHomePage from './useHomePage'
import { SCREENS } from 'services/Analytics/Analytics'
import { PlaybackProvider } from '../../components/PlaybackProvider/PlaybackContext'
import BrowserThemeColor from 'components/ThemeColor/BrowserThemeColor'
import StickyCta from '../../components/StickyCta/StickyCta'
import useScreenSize from '../../hooks/useScreenSize'
import TopBar, { type TopBarProps } from '../../elements/TopBar/TopBar'
import MenuButton from '../../elements/TopBar/Buttons/MenuButton'
import MixtilesLogo from '../../elements/TopBar/Logo/MixtilesLogo'
import { photosTheme as theme } from 'mixtiles-web-common'
import { PHOTO_STYLER_URL, PRODUCTS_URL } from '../../config/config'
import { useScroll, useTransform } from 'framer-motion'
import TopBarBanner from 'elements/TopBar/TopBarBanner/TopBarBanner'
import { useExperimentManager } from '../../services/ExperimentManager/ExperimentManager'
import ChatSupportButton from '../../elements/TopBar/Buttons/ChatSupportButton'
import { getTiles } from '../PhotoStyler/tilesSaver'
import { useTranslation } from 'react-i18next'
import { useNoSSR } from 'utils/NoSSR'

const variantToBarProps = {
  medium: {
    leftButton: (
      <S.ScaleContainer>
        <MenuButton color="white" testId="menu-button" />
      </S.ScaleContainer>
    ),
    rightButton: (
      <S.ScaleContainer>
        <ChatSupportButton color="white" />
      </S.ScaleContainer>
    ),
  },
  shine: {
    leftButton: (
      <S.TopBarLeftContainer>
        <MenuButton color="white" testId="menu-button" />
        <MixtilesLogo color="white" />
      </S.TopBarLeftContainer>
    ),
  },
} satisfies Record<string, TopBarProps>

export default function HomePageContainer() {
  const isClient = useNoSSR()
  const { t } = useTranslation()
  const experimentManager = useExperimentManager()
  const isMediumScreen = useScreenSize('md')
  const showCTA = useShowCTA()
  const isPhotoBooksCoreIntegrationOn =
    !isMediumScreen &&
    experimentManager.isEnabled('photobooks-core-integration-2')

  const { onMainCtaClick } = useHomePage({
    screenName: SCREENS.HOMEPAGE,
    ctaClickUrl: isPhotoBooksCoreIntegrationOn
      ? PRODUCTS_URL
      : PHOTO_STYLER_URL,
  })
  const homePageVariant = experimentManager.getVariantKey(
    'homepage-new-header-2'
  )

  const ctaText = useMemo(() => {
    const tilesCount = isClient ? getTiles().length : 0
    if (tilesCount && !isPhotoBooksCoreIntegrationOn) {
      return homePageVariant === 'treatment-shine-btn' || isMediumScreen
        ? t('homepage.main_button_cta_wip')
        : `${t('pitch.review_button_text')} (${tilesCount})`
    }

    return t('homepage.main_button_cta')
  }, [homePageVariant, isPhotoBooksCoreIntegrationOn, isClient])

  const topBarProps: TopBarProps = useMemo(() => {
    const stickyTopbarBaseProps = {
      backgroundColor: 'transparent',
      noBorder: true,
      absolute: true,
      iconsColor: 'white',
    }
    if (isMediumScreen) {
      return {
        ...stickyTopbarBaseProps,
        ...variantToBarProps.medium,
      }
    } else if (homePageVariant === 'treatment-shine-btn') {
      return {
        ...stickyTopbarBaseProps,
        ...variantToBarProps.shine,
        rightButton: (
          <S.MobileInitialCta onClick={() => onMainCtaClick(true)}>
            {ctaText}
          </S.MobileInitialCta>
        ),
      }
    } else return { backgroundColor: theme.colors.white }
  }, [homePageVariant, isMediumScreen, ctaText])

  const TopBarComponent = isMediumScreen
    ? TopBar.TopContent
    : TopBar.BottomContent

  return (
    <S.Container className="HomePage">
      <BrowserThemeColor
        color={
          homePageVariant === 'treatment-shine-btn'
            ? theme.colors.grayAccent
            : theme.colors.white
        }
      />
      <TopBar {...topBarProps}>
        <TopBarComponent>
          <TopBarBanner />
        </TopBarComponent>
        {isMediumScreen && (
          <TopBar.TitleContent>
            <S.ScaleContainer>
              <MixtilesLogo color="white" />
            </S.ScaleContainer>
          </TopBar.TitleContent>
        )}
        {homePageVariant === 'treatment-shine-btn' && !isMediumScreen && (
          <TopBar.TitleContent />
        )}
      </TopBar>
      <StickyCta
        onClick={onMainCtaClick}
        text={ctaText}
        show={
          !isMediumScreen &&
          ((homePageVariant === 'treatment-post-scroll-btn' && showCTA) ||
            ['off', 'control'].includes(homePageVariant))
        }
        animate
      />
      <PlaybackProvider>
        <HomePageUI
          ctaProps={{
            ctaText,
            onClick: onMainCtaClick,
          }}
        />
      </PlaybackProvider>
    </S.Container>
  )
}

function useShowCTA() {
  const [value, setValue] = useState(false)
  const { scrollYProgress } = useScroll()
  useTransform(scrollYProgress, [0, 0.005, 1], [0, 1, 1]).on('change', (v) =>
    setValue(v === 1)
  )
  return value
}
