import * as S from './SeenOnSection.styles'

import { ReactComponent as nbcLogo } from 'images/homePage/seenOn/nbc.svg'
import { ReactComponent as abcLogo } from 'images/homePage/seenOn/abc.svg'
import { ReactComponent as nypostLogo } from 'images/homePage/seenOn/nypost.svg'
import { ReactComponent as oprahLogo } from 'images/homePage/seenOn/oprah.svg'
import { ReactComponent as todayLogo } from 'images/homePage/seenOn/today.svg'
import useScreenSize from '../../../../hooks/useScreenSize'
import { useExperimentManager } from '../../../../services/ExperimentManager/ExperimentManager'
import { useTranslation } from 'react-i18next'

const logos = [todayLogo, abcLogo, oprahLogo, nbcLogo, nypostLogo]
const TEST_LOGO_URL =
  'https://res.cloudinary.com/mixtiles/image/upload/v1722262364/assets/homepage/2023/seen-on.png'

export default function SeenOnSection() {
  const { t } = useTranslation()
  const experimentManager = useExperimentManager()
  const isMediumScreen = useScreenSize('md')
  if (!isMediumScreen && experimentManager.isEnabled('homepage-new-seen-on')) {
    return (
      <S.TestContainer>
        <S.TestImage src={TEST_LOGO_URL} />
      </S.TestContainer>
    )
  }

  return (
    <>
      <S.Container>
        {!isMediumScreen && (
          <S.TitleContainer>
            <S.FlexSeparator />
            {t('homepage.seen-on')}
            <S.FlexSeparator />
          </S.TitleContainer>
        )}
        <S.LogosContainer>
          {logos.map((Logo, i) => (
            <S.LogoItem key={i}>
              <Logo />
            </S.LogoItem>
          ))}
        </S.LogosContainer>
      </S.Container>
      {!isMediumScreen && <S.WideSeparator />}
    </>
  )
}
