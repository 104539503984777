import React, { useEffect, useRef } from 'react'
import * as S from './TitleSection.styles'
import useScreenSize from '../../../../hooks/useScreenSize'
import { useExperimentManager } from '../../../../services/ExperimentManager/ExperimentManager'
import { type VideoRefType } from '../HomePageVideo/HomePageVideo'
import BrowserThemeColor from '../../../../components/ThemeColor/BrowserThemeColor'
import { useLanguage } from 'services/LangProvider'
import { useTranslation } from 'react-i18next'

type Props = {
  ctaProps: {
    ctaText: string
    onClick: () => void
  }
  onVisibilityChange: (visibilityRatio: number) => void
}

export default function TitleSection({
  ctaProps: { ctaText, onClick },
  onVisibilityChange,
}: Props) {
  const { t } = useTranslation()
  const experimentManager = useExperimentManager()
  const language = useLanguage()
  const isMediumScreen = useScreenSize('md')
  const videoRef = useRef<VideoRefType>(null)

  useEffect(() => {
    const intersectedElement = videoRef.current?.containerRef?.current
    if (!intersectedElement) {
      return
    }

    const observer = new IntersectionObserver(
      ([e]) => {
        onVisibilityChange(e.intersectionRatio)
      },
      { threshold: [0, 0.5, 1] }
    )

    observer.observe(intersectedElement)
    return () => observer.unobserve(intersectedElement)
  }, [videoRef])

  const homepageNewHeader = experimentManager.isEnabled('homepage-new-header-2')
  const headerVariant = experimentManager.getVariantKey('homepage-new-header-2')
  const homepageNewTitle = experimentManager.isEnabled('homepage-new-title')
  const isChristmas = experimentManager.isEnabled('christmas')

  let cldId
  if (isChristmas) {
    cldId = isMediumScreen
      ? 'assets/homepage/christmasDesktop'
      : 'assets/homepage/christmasMobile'
  } else {
    cldId = isMediumScreen
      ? 'assets/homepage/2023/videos/title-1-desktop'
      : 'assets/homepage/2023/videos/title-mobile-new'
  }

  return (
    <S.Video
      cldId={cldId}
      cldWidth={isMediumScreen ? 2000 : undefined}
      name="Header"
      type="Video"
      fetchPriority="high"
      preload="auto"
      autoPlay
      ref={videoRef}
    >
      {headerVariant === 'treatment-shine-btn' && (
        <BrowserThemeColor color="black" />
      )}
      {homepageNewHeader && !isMediumScreen && <S.VideoBackground />}
      <S.TitleWrapper>
        <S.TitleText
          $largerPadding={headerVariant === 'treatment-shine-btn'}
          $newTitle={homepageNewTitle}
          $language={language}
        >
          {homepageNewTitle ? 'Photo Walls Reinvented' : t('homepage.title')}
        </S.TitleText>
        {isMediumScreen && <S.Cta onClick={onClick}>{ctaText}</S.Cta>}
        {experimentManager.isEnabled('christmas') && !isMediumScreen && (
          <S.ChristmasImage
            data-testid="ChristmasImg"
            src="https://d2pkj4iuzqk8im.cloudfront.net/seasonal/MobileChristmas.webp"
          />
        )}
      </S.TitleWrapper>
    </S.Video>
  )
}
