import { styled } from 'styled-components'
import { onMediumScreen, onXXLargeScreen } from 'mixtiles-web-common'

export const Container = styled.div`
  position: relative;
  min-height: 100px;
  padding: 20px 20px 0 20px;

  ${onXXLargeScreen()} {
    transform: scale(1.25);
    transform-origin: center;
  }
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  gap: 20px;
`

export const FlexSeparator = styled.div`
  flex: 1;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.grayMedium};
`
export const WideSeparator = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.grayMedium};
`

export const LogosContainer = styled.ul`
  display: flex;
  gap: 12px;
  list-style: none;
  padding: 0;
  justify-content: space-between;

  ${onMediumScreen()} {
    justify-content: center;
    gap: 40px;
    margin: 0;
  }
`

export const LogoItem = styled.li`
  flex-grow: 1;

  > svg {
    width: 100%;
    height: auto;
  }

  ${onMediumScreen()} {
    flex-grow: 0;

    > svg {
      width: auto;
      height: 70px;
    }
  }
`

export const TestContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 20px;

  ${onXXLargeScreen()} {
    transform: scale(1.25);
    transform-origin: center;
  }
`

export const TestImage = styled.img`
  width: 82vw;
  height: auto;
`
