import '../../App.scss'
import CommunitySection from './Components/CommunitySection/CommunitySection'
import CoveredSection from './Components/CoveredSection/CoveredSection'
import FaqSection from '../../components/FaqSection/FaqSection'
import FooterSection from '../../components/FooterSection/FooterSection'
import FullScreenVideoSection from './Components/FullScreenVideoSection/FullScreenVideoSection'
import PhotoWallSection from './Components/PhotoWallSection/PhotoWallSection'
import SecondaryVideoSection from './Components/SecondaryVideoSection/SecondaryVideoSection'
import SecondaryVideoGallery from './Components/SecondaryVideoSection/SecondaryVideoGallery'
import SeenOnSection from './Components/SeenOnSection/SeenOnSection'
import TitleSection from './Components/TitleSection/TitleSection'
import { FAQS } from '../../components/FaqSection/consts'
import * as S from './HomePageUI.styles'
import useScreenSize from '../../hooks/useScreenSize'
import { useExperimentManager } from '../../services/ExperimentManager/ExperimentManager'
import React, { useState } from 'react'
import MenuButton from '../../elements/TopBar/Buttons/MenuButton'
import MixtilesLogo from '../../elements/TopBar/Logo/MixtilesLogo'
import {
  homepageDesktopVideos,
  homepageMobileVideos,
} from './Components/SecondaryVideoSection/SecondaryVideoSection.consts'
import useOnMount from '../../hooks/useOnMount'
import { useTranslation } from 'react-i18next'

type Props = {
  ctaProps: {
    ctaText: string
    onClick: () => void
  }
}

export default function HomePageUI({ ctaProps }: Props) {
  const { t } = useTranslation()
  const experimentManager = useExperimentManager()
  const isMediumScreen = useScreenSize('md')
  const [showStickyTopBar, setShowStickyTopBar] = useState(false)
  const showBtn = ['treatment-shine-btn', 'treatment'].includes(
    experimentManager.getVariantKey('homepage-new-header-2')
  )
  useOnMount(() => {
    if (experimentManager.isEnabled('homepage-new-title')) {
      homepageDesktopVideos[0].titleKey =
        'homepage.secondary_title_1_desktop_exp'
    }
  })

  return (
    <S.Main>
      {(showBtn || isMediumScreen) && (
        <S.StickyTopBar
          initial={{
            translateY: -80,
          }}
          animate={{
            translateY: showStickyTopBar ? 0 : -80,
          }}
          transition={{
            duration: 0.2,
          }}
        >
          <S.TopBarLeftContainer>
            <MenuButton testId="menu-button-header" />
            <MixtilesLogo />
          </S.TopBarLeftContainer>
          <S.StickyTopBarCta
            onClick={ctaProps.onClick}
            $shine={
              experimentManager.getVariantKey('homepage-new-header-2') ===
              'treatment-shine-btn'
            }
          >
            {ctaProps.ctaText}
          </S.StickyTopBarCta>
        </S.StickyTopBar>
      )}
      <S.WidthCapList $fullscreen>
        <TitleSection
          ctaProps={ctaProps}
          onVisibilityChange={(visibilityRatio) =>
            setShowStickyTopBar(
              isMediumScreen ? visibilityRatio <= 0.1 : visibilityRatio <= 0.5
            )
          }
        />
        <SeenOnSection />
        {isMediumScreen ? (
          <SecondaryVideoGallery videos={homepageDesktopVideos} />
        ) : (
          <SecondaryVideoSection videos={homepageMobileVideos} />
        )}
        <FullScreenVideoSection
          cldId={
            isMediumScreen
              ? 'assets/homepage/2023/videos/quality-desktop-2024'
              : 'assets/homepage/2023/videos/quality'
          }
          videoText={
            isMediumScreen
              ? t('homepage.fullscreen_title_desktop')
              : t('homepage.fullscreen_title')
          }
        />
      </S.WidthCapList>
      {!isMediumScreen && (
        <S.WallContainer>
          <S.WidthCapList>
            <PhotoWallSection />
          </S.WidthCapList>
        </S.WallContainer>
      )}

      <S.CommunityContainer>
        <S.WidthCapList>
          <CommunitySection />
        </S.WidthCapList>
      </S.CommunityContainer>

      <S.EndingContainer>
        <S.WidthCapList>
          <CoveredSection />
          <FaqSection faqs={FAQS} source="Homepage" />
        </S.WidthCapList>
      </S.EndingContainer>

      <S.FooterContainer>
        <S.WidthCapList>
          <FooterSection />
        </S.WidthCapList>
      </S.FooterContainer>
    </S.Main>
  )
}
