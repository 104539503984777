import { useScroll, useTransform } from 'framer-motion'
import * as S from './PhotoWallSection.styles'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'

export default function PhotoWallSection() {
  const { t } = useTranslation()
  const containerRef = useRef<HTMLDivElement>(null)
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ['start end', 'start start'],
  })
  const translateY = useTransform(scrollYProgress, [0, 1], [50, 0])

  return (
    <S.Container ref={containerRef} style={{ translateY }}>
      <S.TextWrapper>
        <S.Title>{t('homepage.photo_wall.title')}</S.Title>
        <S.Subtitle>{t('homepage.photo_wall.subtitle')}</S.Subtitle>
      </S.TextWrapper>
      <S.Video
        cldId="assets/homepage/2023/videos/phone"
        name="Photo Wall"
        type="Video"
        fit="contain"
        position="bottom"
        fetchPriority="low"
      />
    </S.Container>
  )
}
