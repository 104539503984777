import { styled } from 'styled-components'
import HomePageVideo from '../HomePageVideo/HomePageVideo'
import { m } from 'framer-motion'
import { LANGUAGES } from '../../../../locale/Translation.consts'
import { translateManager } from '../../../../services/TranslateManager'

export const Container = styled(m.div)`
  padding-top: 56px;
  text-align: center;
  will-change: transform;
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${() =>
    translateManager.getLanguage() === LANGUAGES.ENGLISH ? '0 40px' : '0 20px'};
  gap: 10px;
`
export const Title = styled.h2`
  font-size: 28px;
  font-weight: 700;
  line-height: 120%;
  margin: 0;
  max-width: ${() =>
    translateManager.getLanguage() === LANGUAGES.ENGLISH ? '270px' : '330px'};
`
export const Subtitle = styled.h3`
  font-size: 17px;
  font-weight: 400;
  margin: 0;
`

export const Video = styled(HomePageVideo)`
  margin-top: 32px;
  height: 60vh;
  max-height: 450px;
  max-width: 100%;
  width: 100%;
  object-position: bottom;
  object-fit: fill;
`
